import React from 'react'
import moment from 'moment'
import { Link } from 'gatsby'
import './postPreview.scss'

function PostPreview({ title, date, description, slug }) {
  const formattedDate = moment(date).format('YYYY-MM-DD hh:mm')
  return (
    <article className="preview-container">
      <div className="preview-row">
        <span className="preview-title">
          <Link to={slug}>{title}</Link>
        </span>
        <span className="preview-create-date">
          <time datatype={formattedDate}>
            <i>Posted: {formattedDate}</i>
          </time>
        </span>
      </div>
      <hr />
      <p className="preview-description">
        <i>Description: {description}</i>
      </p>
    </article>
  )
}

export default PostPreview
