import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import PostPreview from '../components/postPreview'

const Posts = ({ data }) => (
  <Layout>
    <SEO title="Posts" />
    {data.allMarkdownRemark.edges.map(edge => {
      return (
        <PostPreview slug={edge.node.fields.slug} {...edge.node.frontmatter} />
      )
    })}
  </Layout>
)

export const query = graphql`
  query PostPageQuery {
    allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/posts/" } }) {
      edges {
        node {
          html
          id
          fileAbsolutePath
          fields {
            slug
          }
          frontmatter {
            path
            title
            date
            description
          }
        }
      }
    }
  }
`

export default Posts
